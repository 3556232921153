import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Footer } from "../../components";

const HyaluronicPage = () => {
  const [hopeOptions, setHopeOptions] = useState([
    { date: "", time: "" },
    { date: "", time: "" },
    { date: "", time: "" },
  ]);

  const [dateOptions, setDateOptions] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);

  useEffect(() => {
    // 現在の日付を取得
    const currentDate = new Date();

    // 1ヶ月後までの日付を生成
    const dateOptions = [];
    for (let i = 1; i < 30; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + i);

      // 月曜日または水曜日の場合はスキップ
      if (date.getDay() !== 1 && date.getDay() !== 3) {
        dateOptions.push({
          value: date.toISOString().split("T")[0],
          label: formatDate(date),
        });
      }
    }

    // 営業時間内の時間を生成
    const timeOptions = [];
    const selectedDay = new Date(hopeOptions[0].date);
    const startTime = isSaturday(selectedDay) ? 10.25 * 60 : 10.25 * 60;
    const endTime = isSaturday(selectedDay) ? 15.5 * 60 : 16.5 * 60;
    const interval = 15;

    for (let minutes = startTime; minutes <= endTime; minutes += interval) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      const timeString = `${hours.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}`;
      timeOptions.push({
        value: timeString,
        label: timeString,
      });
    }

    // オプションをセット
    setDateOptions(dateOptions);
    setTimeOptions(timeOptions);
  }, [hopeOptions]);

  const isSaturday = (date) => {
    return date.getDay() === 6;
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (event, index) => {
    const newHopeOptions = [...hopeOptions];
    newHopeOptions[index].date = event.target.value;
    setHopeOptions(newHopeOptions);
  };

  const handleTimeChange = (event, index) => {
    const newHopeOptions = [...hopeOptions];
    newHopeOptions[index].time = event.target.value;
    setHopeOptions(newHopeOptions);
  };

  const [values, setValues] = useState({
    treatment: "",
    hopeDate1: "",
    hopeDate2: "",
    hopeDate3: "",
    hopeTime1: "",
    hopeTime2: "",
    hopeTime3: "",
    parts: "",
    nameKanji: "",
    nameKana: "",
    email: "",
    tel: "",
    birthday: "",
    message: "",
  });

  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch("/api/lp-form-hyaluronic", {
        method: "POST",
        body: JSON.stringify({ values }),
      });
      console.log("response.status", response.status);
      if (response.status === 200) {
        console.log("entered");
        navigate("/contact/success");
      } else {
        setError("入力欄が正しくありません");
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  return (
    <>
      <Helmet>
        <title>[広告用]ヒアルロン酸注射</title>
        <meta
          name="facebook-domain-verification"
          content="nydysltydrgwzxxtvp8nsr7ldmu9po"
        />
      </Helmet>
      <header className="max-w-sp mx-auto">
        <div className="fixed top-0 left-1/2 -translate-x-1/2 z-50 w-sp bg-primary-pink h-16 bg-opacity-80">
          <div className="w-full h-full flex items-center justify-between px-3">
            <div className="flex items-center gap-2">
              <StaticImage
                className="w-10"
                src="../../../content/assets/logo.svg"
                alt=""
                quality="100"
              />
              <span className="text-white text-lg font-semibold text-center">
                恵比寿美容皮膚科
              </span>
            </div>
            <a
              href="#form"
              className="bg-[#d71362] rounded-full text-xs font-sans text-white font-bold p-2"
            >
              無料カウンセリング ▼
            </a>
          </div>
        </div>
      </header>
      <main className="max-w-sp mx-auto mt-16">
        <div id="kv" className="relative mb-9">
          <h1>
            <StaticImage
              className="w-full"
              src="../../../content/assets/lp/hyaluronic-acid/kv.png"
              alt="恵比寿美容皮膚科のヒアルロン酸注射！累計70000件の施術実績あり！即効性のあるヒアルロン酸注射で気になるシワ・ほうれい線の改善、顔のパーツを理想の形へ、大切な予定の前に綺麗を取り戻す！恵比寿美容皮膚科はなりたい自分を叶えます！"
              quality="100"
            />
          </h1>
          <Link
            to="#form"
            className="block absolute bottom-4 left-1/2 -translate-x-1/2"
          >
            <StaticImage
              className="w-80"
              src="../../../content/assets/lp/hyaluronic-acid/btn.png"
              alt="無料カウンセリングはこちら"
              quality="100"
            />
          </Link>
        </div>
        <div id="trouble" className="mb-2">
          <h2>
            <StaticImage
              className="w-full"
              src="../../../content/assets/lp/hyaluronic-acid/trouble.png"
              alt="こんなお悩みありませんか？①以前の自分のように若々しくなりたい。シワやたるみを改善したい、ほうれい線を薄くしたい、ちりめんじわ・小じわを薄くしたい、老けた印象を改善したい、目元のたるみやクマを変えたい。②施術とバレずに理想の形に変えたい。少し鼻を高くしたい、自然な涙袋を作りたい、ぷるんとした唇に、あごをシャープに。"
              quality="100"
            />
          </h2>
        </div>
        <div id="solution" className="mb-3">
          <h3>
            <div className="w-[330px] mx-auto">
              <StaticImage
                className="w-full"
                src="../../../content/assets/lp/hyaluronic-acid/solution.png"
                alt="そんな方にはヒアルロン酸注射（注入）がおすすめ！[ヒアルロン酸注射のいいところ]ダウンタイムがほとんどない、傷跡が残らない、施術時間が短い。だから、負担が少なく、すぐに効果が実感できる！"
                quality="100"
              />
            </div>
          </h3>
        </div>
        <div id="effect">
          <h3>
            <StaticImage
              className="w-full"
              src="../../../content/assets/lp/hyaluronic-acid/effect.png"
              alt=""
              quality="100"
            />
          </h3>
        </div>
        <div id="before-after" className="relative">
          <h3>
            <StaticImage
              className="w-full"
              src="../../../content/assets/lp/hyaluronic-acid/before-after.png"
              alt=""
              quality="100"
            />
          </h3>
          <Link
            to="#form"
            className="block absolute bottom-8 z-10 left-1/2 -translate-x-1/2"
          >
            <StaticImage
              className="w-80"
              src="../../../content/assets/lp/hyaluronic-acid/btn.png"
              alt="無料カウンセリングはこちら"
              quality="100"
            />
          </Link>
        </div>
        <div id="reasons" className="relative">
          <h3>
            <StaticImage
              className="w-full"
              src="../../../content/assets/lp/hyaluronic-acid/reasons.png"
              alt=""
              quality="100"
            />
          </h3>
        </div>
        <div id="price">
          <StaticImage
            className="w-full"
            src="../../../content/assets/lp/hyaluronic-acid/price.png"
            alt=""
            quality="100"
          />
        </div>
        <div id="flow" className="relative">
          <h3>
            <StaticImage
              className="w-full"
              src="../../../content/assets/lp/hyaluronic-acid/flow.png"
              alt=""
              quality="100"
            />
          </h3>
          <Link
            to="#form"
            className="block absolute bottom-9 left-1/2 -translate-x-1/2"
          >
            <StaticImage
              className="w-80"
              src="../../../content/assets/lp/hyaluronic-acid/btn.png"
              alt="無料カウンセリングはこちら"
              quality="100"
            />
          </Link>
        </div>
        <div id="faq" className="relative">
          <div className="absolute inset-0 -z-1">
            <StaticImage
              className="w-full absolute inset-0"
              src="../../../content/assets/lp/hyaluronic-acid/faq-bg.png"
              alt="背景"
              quality="100"
            />
          </div>
          <h3 className="text-xl text-center py-6 font-bold text-gray-600 font-sans">
            ヒアルロン酸注入の
            <br />
            よくあるご質問
          </h3>
          <ul className="flex flex-col gap-6 px-5 font-sans text-gray-700">
            <li className="flex flex-col gap-2.5 border-b border-gray-700 pb-6">
              <div className="flex items-center gap-2.5">
                <span>
                  <StaticImage
                    className="w-8"
                    src="../../../content/assets/lp/hyaluronic-acid/question.png"
                    alt="質問"
                    quality="100"
                  />
                </span>
                <h4 className="font-bold">副作用などはありますか？</h4>
              </div>
              <div className="flex gap-2.5">
                <span>
                  <StaticImage
                    className="w-8"
                    src="../../../content/assets/lp/hyaluronic-acid/answer.png"
                    alt="質問"
                    quality="100"
                  />
                </span>
                <p className="leading-7">
                  基本的に、ヒアルロン酸注射の副作用はほとんどありません。
                  <br />
                  場合によって、以下の症状などがまれに現れるケースがあります。
                  <br />
                  ●注射による内出血
                  <br />
                  　（1～2週間ほどで解消されます）
                  <br />
                  ●かゆみ・赤み・むくみ
                  <br />
                  　（1週間ほどで解消されます）
                  <br />
                  ●術後、注入部分をマッサージはお控
                  <br />
                  　えください。
                  <br />
                  詳しくは、無料カウンセリング時にドクターにご確認ください。
                </p>
              </div>
            </li>
            <li className="flex flex-col gap-2.5 border-b border-gray-700 pb-6">
              <div className="flex gap-2.5">
                <span>
                  <StaticImage
                    className="w-8"
                    src="../../../content/assets/lp/hyaluronic-acid/question.png"
                    alt="質問"
                    quality="100"
                  />
                </span>
                <h4 className="font-bold">
                  施術の痛みはありますか？
                  <br />
                  どれくらいですか？
                </h4>
              </div>
              <div className="flex gap-2.5">
                <span>
                  <StaticImage
                    className="w-8"
                    src="../../../content/assets/lp/hyaluronic-acid/answer.png"
                    alt="質問"
                    quality="100"
                  />
                </span>
                <p className="leading-7">
                  施術の実施前に局部麻酔をいたしますので施術による痛みは感じません。
                  <br />
                  麻酔が切れてからも痛みを感じることはほとんどありません。
                  <br />
                  施術後に不安がありましたらいつでもご連絡くださいませ。
                </p>
              </div>
            </li>
            <li className="flex flex-col gap-2.5 pb-10">
              <div className="flex gap-2.5">
                <span>
                  <StaticImage
                    className="w-8"
                    src="../../../content/assets/lp/hyaluronic-acid/question.png"
                    alt="質問"
                    quality="100"
                  />
                </span>
                <h4 className="font-bold">
                  ヒアルロン酸注射をした部位は、
                  <br />
                  効果が薄れたら前よりたるみますか？
                </h4>
              </div>
              <div className="flex gap-2.5">
                <span>
                  <StaticImage
                    className="w-8"
                    src="../../../content/assets/lp/hyaluronic-acid/answer.png"
                    alt="質問"
                    quality="100"
                  />
                </span>
                <p className="leading-7">
                  時間が経ってヒアルロン酸が吸収されても、注入前の状態に戻るだけで注入前よりもたるんだり、シワが深くなることはありませんのでご安心下さい。
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div id="intro" className="relative">
          <div className="absolute inset-0 -z-1">
            <StaticImage
              className="w-full"
              src="../../../content/assets/lp/hyaluronic-acid/intro-bg.png"
              alt="背景"
              quality="100"
            />
          </div>
          <StaticImage
            className="mt-9 mb-5 w-[273px]"
            src="../../../content/assets/lp/hyaluronic-acid/intro-title.png"
            alt="恵比寿美容皮膚科のご紹介"
            quality="100"
          />
          <p className="mb-6 leading-7 font-sans px-5 text-gray-500">
            恵比寿駅から徒歩6分。
            <br />
            駅前から少し離れたほどよいアクセスで
            <br />
            人目が気になる方でも安心して
            <br />
            お越しいただけます。
          </p>
          <StaticImage
            className="w-[335px] mx-auto block mb-5"
            src="../../../content/assets/lp/hyaluronic-acid/info.png"
            alt="恵比寿美容皮膚科のご紹介"
            quality="100"
          />
          <h4 className="font-bold text-gray-500 font-sans px-8 mb-5">
            〒150-0013
            <br />
            東京都渋谷区恵比寿1丁目26-17
            <br />
            阿部ビル3F
            <br />
            ★おしゃれなカフェが目印です
          </h4>
          <div className="px-5 mb-6">
            <div className="w-full h-[230px]">
              <iframe
                title="ebisu-map"
                className="w-full h-full rounded-lg"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.209407649846!2d139.7134347156913!3d35.64721198020218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bc2dcd510cb%3A0x1f964f006ac576d0!2z5oG15q-U5a-_576O5a6555qu6Iaa56eR!5e0!3m2!1sja!2sjp!4v1633358987572!5m2!1sja!2sjp"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="px-5">
            <ul className="flex flex-col gap-2.5 mb-8">
              <li className="flex items-center gap-2.5">
                <StaticImage
                  className="w-[101px]"
                  src="../../../content/assets/lp/hyaluronic-acid/tel-title.png"
                  alt="電話番号"
                  quality="100"
                />
                <a
                  href="tel:03-6450-4531"
                  className="font-sans text-gray-500 font-bold"
                >
                  03-6450-4531
                </a>
              </li>
              <li className="flex items-center gap-2.5">
                <StaticImage
                  className="w-[101px]"
                  src="../../../content/assets/lp/hyaluronic-acid/hours-title.png"
                  alt="診療時間"
                  quality="100"
                />
                <h5 className="font-sans text-gray-500 font-bold">
                  10:00〜19:00
                </h5>
              </li>
              <li className="flex items-center gap-2.5">
                <StaticImage
                  className="w-[101px]"
                  src="../../../content/assets/lp/hyaluronic-acid/close-title.png"
                  alt="定休日"
                  quality="100"
                />
                <h5 className="font-sans text-gray-500 font-bold">
                  月・水
                  <br />
                  {/* <span className="block text-xs transform scale-75">
                    ※月・水のご連絡はお返事が翌日になります。
                  </span> */}
                </h5>
              </li>
              <li>
                <span className="block font-sans text-gray-500 font-bold text-xs">
                  ※月・水のご連絡はお返事が翌日になります。
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div id="form" className="px-5 bg-[#f7e7ea] py-9 font-sans">
          <h2 className="font-bold text-center text-[#d71362] text-2xl mb-6">
            無料カウンセリング
            <br />
            ご予約フォーム
          </h2>
          <form
            className="flex flex-col gap-8 font-bold text-gray-500"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <legend className="text-xl flex items-center gap-2.5">
                <span className="bg-[#d71362] text-sm p-1 text-white rounded tracking-widest">
                  必須
                </span>
                診療
              </legend>
              <div className="ml-11 mt-2 flex items-center gap-5">
                <div className="lp-radio">
                  <input
                    type="radio"
                    id="first-time"
                    className="visually-hidden"
                    name="treatment"
                    value="first-time"
                    required
                    onChange={(e) =>
                      setValues({ ...values, treatment: e.target.value })
                    }
                  />
                  <label htmlFor="first-time">初診</label>
                </div>
                <div className="lp-radio">
                  <input
                    type="radio"
                    id="multiple-time"
                    className="visually-hidden"
                    name="treatment"
                    value="再診"
                    required
                    onChange={(e) =>
                      setValues({ ...values, treatment: e.target.value })
                    }
                  />
                  <label htmlFor="multiple-time">再診</label>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend className="text-xl flex items-center gap-2.5">
                ご希望の日時
              </legend>
              <div className="mt-2 flex flex-col gap-5">
                {hopeOptions.map((hope, index) => (
                  <div
                    key={index}
                    className={`flex flex-col gap-2.5 pb-5 ${
                      index === 2 ? "" : "border-b border-gray-500"
                    }`}
                  >
                    <select
                      id={`hopeDate${index + 1}`}
                      name={`hopeDate${index + 1}`}
                      className="w-full py-2 px-3 text-sm tracking-wider rounded"
                      value={hope.date}
                      onChange={(e) => {
                        handleDateChange(e, index);

                        setValues((prevValues) => {
                          const newValues = { ...prevValues };
                          newValues[`hopeDate${index + 1}`] = e.target.value;
                          return newValues;
                        });
                      }}
                    >
                      <option value="">第{index + 1}候補日</option>
                      {dateOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <select
                      id={`hopeTime${index + 1}`}
                      name={`hopeTime${index + 1}`}
                      className="w-full py-2 px-3 text-sm tracking-wider rounded"
                      value={hope.time}
                      onChange={(e) => {
                        handleTimeChange(e, index);

                        setValues((prevValues) => {
                          const newValues = { ...prevValues };
                          newValues[`hopeTime${index + 1}`] = e.target.value;
                          return newValues;
                        });
                      }}
                    >
                      <option value="">時間</option>
                      {timeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            </fieldset>
            <fieldset>
              <legend className="text-xl flex items-center gap-2.5">
                <span className="bg-[#d71362] text-sm p-1 text-white rounded tracking-widest">
                  必須
                </span>
                ご希望の部位
              </legend>
              <div className="mt-2">
                <input
                  type="text"
                  id="parts"
                  name="parts"
                  onChange={(e) =>
                    setValues({ ...values, parts: e.target.value })
                  }
                  className="w-full py-2 px-3 text-sm tracking-wider rounded"
                  required
                />
              </div>
            </fieldset>
            <fieldset>
              <legend className="text-xl flex items-center gap-2.5">
                <span className="bg-[#d71362] text-sm p-1 text-white rounded tracking-widest">
                  必須
                </span>
                お名前（漢字）
              </legend>
              <div className="mt-2">
                <input
                  type="text"
                  id="nameKanji"
                  name="nameKanji"
                  onChange={(e) =>
                    setValues({ ...values, nameKanji: e.target.value })
                  }
                  className="w-full py-2 px-3 text-sm tracking-wider rounded"
                  required
                />
              </div>
            </fieldset>
            <fieldset>
              <legend className="text-xl flex items-center gap-2.5">
                <span className="bg-[#d71362] text-sm p-1 text-white rounded tracking-widest">
                  必須
                </span>
                お名前（ふりがな）
              </legend>
              <div className="mt-2">
                <input
                  type="text"
                  id="nameKana"
                  name="nameKana"
                  onChange={(e) =>
                    setValues({ ...values, nameKana: e.target.value })
                  }
                  className="w-full py-2 px-3 text-sm tracking-wider rounded"
                  required
                />
              </div>
            </fieldset>
            <fieldset>
              <legend className="text-xl flex items-center gap-2.5">
                <span className="bg-[#d71362] text-sm p-1 text-white rounded tracking-widest">
                  必須
                </span>
                メールアドレス
              </legend>
              <div className="mt-2">
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={(e) =>
                    setValues({ ...values, email: e.target.value })
                  }
                  className="w-full py-2 px-3 text-sm tracking-wider rounded"
                  required
                />
              </div>
            </fieldset>
            <fieldset>
              <legend className="text-xl flex items-center gap-2.5">
                <span className="bg-[#d71362] text-sm p-1 text-white rounded tracking-widest">
                  必須
                </span>
                電話番号
              </legend>
              <div className="mt-2">
                <input
                  type="tel"
                  id="tel"
                  name="tel"
                  onChange={(e) =>
                    setValues({ ...values, tel: e.target.value })
                  }
                  className="w-full py-2 px-3 text-sm tracking-wider rounded"
                  required
                />
              </div>
            </fieldset>
            <fieldset>
              <legend className="text-xl flex items-center gap-2.5">
                <span className="bg-[#d71362] text-sm p-1 text-white rounded tracking-widest">
                  必須
                </span>
                生年月日
              </legend>
              <div className="mt-2">
                <input
                  type="date"
                  id="birthday"
                  name="birthday"
                  onChange={(e) =>
                    setValues({ ...values, birthday: e.target.value })
                  }
                  // value="1990-01-01"
                  className="w-full py-2 px-3 text-sm tracking-wider rounded"
                  required
                />
              </div>
            </fieldset>
            <fieldset>
              <legend className="text-xl flex items-center gap-2.5">
                メッセージ
              </legend>
              <div className="mt-2">
                <textarea
                  id="message"
                  className="w-full py-2 px-3 text-sm tracking-wider rounded font-normal"
                  name="message"
                  onChange={(e) =>
                    setValues({ ...values, message: e.target.value })
                  }
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </fieldset>
            {error ? (
              <div className="px-3 py-3">
                <h4 className="text-red-500">{error}</h4>
              </div>
            ) : null}
            <button type="submit">
              <StaticImage
                className="w-[170px] block mx-auto"
                src="../../../content/assets/lp/hyaluronic-acid/submit-btn.png"
                alt="診療時間"
                quality="100"
              />
            </button>
          </form>
        </div>
      </main>
      <div className="bg-primary-pink">
        <Footer />
      </div>
    </>
  );
};

export default HyaluronicPage;
